@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.principes {
  padding: 30px 0;
  text-align: center;
  .title {
    font-family: variable.$Freeman;
    color: variable.$primaryColor;
    font-size: 6.3vw;
    margin-bottom: 8vw;
    padding: 0 15px;
    strong {
      display: block;
      font-family: variable.$CaveatBrush;
      font-size: 9vw;
    }
  }
  .item {
    margin-bottom: 30px;
    img {
      height: 11vw;
      width: auto;
      margin: 0 auto 4vw;
    }
    .subtitle {
      font-family: variable.$Freeman;
      color: variable.$primaryColor;
      font-size: 5.3vw;
      text-transform: uppercase;
    }
    .description {
      font-family: variable.$primaryFont;
      color: variable.$blackColor;
      font-size: 3.7vw;
      line-height: 5vw;
      margin-top: 10px;
      strong {
        font-family: variable.$primaryFontSemiBold;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include mixin.mediaTabletSize() {
  .principes {
    padding-bottom: 25px;
    > div {
      max-width: 1200px;
    }
    .title {
      font-size: variable.$size34;
      margin-bottom: 30px;
      padding: 0 15px;
      line-height: 43px;
      strong {
        font-size: 48px;
      }
      > * {
        padding-bottom: 23px;
        background-size: 140px auto;
        background-position: right bottom;
        &::after {
          width: 15px;
          height: 32px;
          margin-left: 10px;
        }
      }
    }
    .item {
      &:nth-child(2) {
        img {
          height: 38px;
          margin-top: 7px;
        }
      }

      &:first-child {
        img {
          height: 40px;
          margin-top: 5px;
        }
      }
      img {
        width: auto;
        height: 45px;
        margin: 0 auto 15px;
      }
      .subtitle {
        font-size: variable.$size20;
        margin-bottom: 15px;
      }
      .description {
        font-size: variable.$size14;
        line-height: variable.$size22;
      }
    }
  }
}
